<template>
    <div class="card p-3">
        <div class="flex flex-row justify-content-between">
            <div class="text-800 mb-1">
                <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                    <AppInfoManual nomeTelaDoManual="tela-chamada-administrativa-form" />
                    <h3 class="ml-1">Listagem Administrativa</h3>
                </div>
            </div>
            <div class="flex justify-content-center align-itens-center text-800 mb-1">
                <div class="flex justify-content-center align-itens-center text-800 mb-1">
                    <Button
                        label="Voltar para listagem Padrão"
                        @click="$router.push({ name: 'tela-chamada-profissional' })"
                        class="p-button-link p-0 m-0 ml-auto"
                    />
                </div>
            </div>
        </div>
        <div class="flex flex-row">
            <div class="p-fluid mt-2 w-full md:mt-0 md:w-5 grid-nogutter">
                <Chips v-model="pesquisa" placeholder="Pesquisar" @keyup.enter="loadingLoad" @add="loadingLoad" @remove="loadingLoad" />
            </div>
            <div class="p-fluid mt-5 w-full md:mt-0 md:w-5 grid-nogutter">
                <Button
                    type="button"
                    v-tooltip.bottom="'Recarregar listagem'"
                    class="ml-2"
                    @click="loadingLoad"
                    icon="pi pi-sync"
                    loadingIcon="pi pi-sync pi-spin"
                    :loading="loading"
                />
            </div>
            <DropdownAmbiente class="dropdownAmbiente w-25rem" @change="loadingLoad" id="ambiente" v-model="ambiente" />
        </div>
        <Divider />
        <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
        <div :style="loading ? 'opacity: 30%;' : ''">
            <div class="body">
                <DataTable class="p-datatable-sm" :value="atendimentos" :scrollable="true" scrollHeight="flex" responsiveLayout="stack">
                    <template #empty> Nenhum registro encontrado. </template>
                    <Column field="name" header="Paciente" :sortable="true" style="flex: 0 0 20rem"></Column>
                    <Column field="procedimentos" header="Procedimentos" :sortable="true"></Column>
                    <Column field="responsavel.name" header="Médico Responsável" :sortable="true"></Column>
                    <Column field="tipoExame" header="Tipo Exame" :sortable="true" style="flex: 0 0 10rem"></Column>
                    <Column field="horarioMarcado" header="Horário Marcado" :sortable="true" style="flex: 0 0 10rem"> </Column>
                    <Column field="sala" header="Ambiente" :sortable="true" style="flex: 0 0 10rem"></Column>
                    <Column field="status" header="Status" :sortable="true" style="flex: 0 0 10rem">
                        <template #body="slotProps">
                            <div
                                class="bg-green-500 border-round-3xl h-3rem w-full flex justify-content-center align-items-center text-white"
                                v-if="emAberto(slotProps.data.status)"
                            >
                                {{ renderTipoHorario(slotProps.data?.status) }}
                            </div>
                            <div
                                class="bg-primary border-round-3xl h-3rem w-full flex justify-content-center align-items-center text-white"
                                v-if="emAndamento(slotProps.data.status)"
                            >
                                {{ renderTipoHorario(slotProps.data?.status) }}
                            </div>
                            <div
                                class="bg-orange-400 border-round-3xl h-3rem w-full flex justify-content-center align-items-center text-white"
                                v-if="concluido(slotProps.data.status)"
                            >
                                {{ renderTipoHorario(slotProps.data?.status) }}
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import BaseService from '@/services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import StatusAtendimento from '@/enums/StatusAtendimento';
import * as storage from '../../common/storage';
import DropdownAmbiente from '@/components/input/DropdownAmbiente.vue';
import { getCompany } from '@/services/auth';

export default {
    components: { DropdownAmbiente },
    data() {
        return {
            tenantCustomers: [],
            atendimentos: [],
            paciente: {},
            profile: {},
            service: null,
            ambiente: null,
            pesquisa: null,
            loading: false,
            showDialogChamarPaciente: false,
            empresa: {}
        };
    },
    async mounted() {
        this.profile = storage.getProfile();
        this.tenantCustomers = getCurrentCustomers();
        this.empresa = await getCompany();
        this.loadingLoad();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    watch: {
        tenant() {
            this.loadingLoad();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.loadingLoad();
        }
    },
    methods: {
        isResponsavelPeloAtendimento(responsavelId) {
            if (responsavelId == this.profile.id) {
                return true;
            }
            return false;
        },
        emAberto(status) {
            if (status == StatusAtendimento.EM_ABERTO) {
                return true;
            }
            return false;
        },
        emAndamento(status) {
            if (status == StatusAtendimento.EM_ANDAMENTO) {
                return true;
            }
            return false;
        },
        concluido(status) {
            if (status == StatusAtendimento.CONCLUIDO) {
                return true;
            }
            return false;
        },
        async loadingLoad() {
            this.loading = true;
            await this.autoLoad();
            this.loading = false;
        },
        async load() {
            try {
                const service = new BaseService('/atendimento_check_in');
                const filtrosExtras = {
                    ambienteId: this.ambiente?.id,
                    concluidos: true,
                    pesquisa: this.pesquisa
                };
                const { data } = await service.findAll({ filtrosExtras });
                this.atendimentos = data;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Ops, algo de errado aconteceu!', detail: error.message, life: 8000 });
            }
        },
        async autoLoad() {
            await this.load();
            setTimeout(async () => {
                await this.autoLoad();
            }, 5000);
        },
        renderTipoHorario(status) {
            switch (status) {
                case StatusAtendimento.EM_ABERTO:
                    return 'Em Aberto';
                case StatusAtendimento.EM_ANDAMENTO:
                    return 'Em Andamento';
                case StatusAtendimento.CONCLUIDO:
                    return 'Concluído';
                default:
                    return 'Não Informado.';
            }
        }
    }
};
</script>
<style scoped lang="scss">
.body {
    transition: all 300ms;
    overflow: auto;
    height: 68vh;
    max-height: 100%;
}

.dropdownAmbiente {
    padding: 0px;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-width: 2px;
}
</style>
